import React, { FC } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Pagination, WelcomeBanner, PostCard } from 'src/components/Blog';
import { postCardAdapter } from 'src/components/Blog/PostCard/adapters';

// components
import { TemplateWrapper } from 'src/templates';
import { ContentfulToolbar } from 'src/components/ContentfulToolbar';

// helpers
import { ContentfulTemplates } from 'src/lib/constants';
import { Entry, Sys } from 'contentful';

// styles
import styles from './BlogOverviewTemplate.module.scss';

interface PageMetaFields {
  heading?: string;
  metaDescription?: string;
  metaTitle?: string;
}

interface BlogPost {
  fields?: PageMetaFields;
  heading?: string;
  metaDescription?: string;
  metaTitle?: string;
  sys?: Sys;
}

interface BlogOverviewTemplateProps {
  posts: Entry<BlogPost>[];
  fields: {
    metaTitle?: string;
    metaDescription?: string;
    pageSlug?: string;
    hasFooterCta?: boolean;
  };
  heading: string;
  pageNum: number;
  totalPages: number;
  hasFeaturedPost: boolean;
  basePath: string;
  noIndex?: boolean;
  cmsTheme?: string;
}

const BlogOverviewScripts = dynamic(
  () => {
    return import(
      'src/templates/BlogOverviewTemplate/BlogOverviewTemplateScripts'
    );
  },
  {
    ssr: false,
  },
);

const isFeaturedPost = ({ pageNum, hasFeaturedPost, i }) => {
  return pageNum === 1 && hasFeaturedPost && i === 0;
};

const BlogOverviewTemplate: FC<BlogOverviewTemplateProps> = ({
  fields,
  posts = [],
  heading,
  pageNum,
  totalPages,
  hasFeaturedPost,
  basePath,
  noIndex = false,
  cmsTheme = 'web 2.0',
  ...props
}) => {
  const router = useRouter();
  return (
    <TemplateWrapper
      meta-title={fields?.metaTitle}
      meta-description={fields?.metaDescription}
      hasFooterCta={fields?.hasFooterCta}
      className='blog'
      noIndex={noIndex}
      cmsTheme={cmsTheme}
    >
      <WelcomeBanner title={heading} />

      <div className={styles.contentWrapper}>
        <div
          data-testid={ContentfulTemplates.BLOG_OVERVIEW}
          className={styles.blogList}
        >
          {posts.map((post, i) => {
            try {
              return (
                <PostCard
                  {...postCardAdapter(post?.fields)}
                  featured={isFeaturedPost({ pageNum, hasFeaturedPost, i })}
                  key={`blog-post-${i}`}
                />
              );
            } catch {
              return null;
            }
          })}
        </div>
        <Pagination
          currentPage={pageNum}
          totalPages={totalPages}
          basePath={basePath}
        />
      </div>
      <BlogOverviewScripts route={router.route} {...props} />
      <ContentfulToolbar />
    </TemplateWrapper>
  );
};

export default BlogOverviewTemplate;
